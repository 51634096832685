import {
    type FunctionComponent,
    type ChangeEvent,
    type FocusEvent,
    useCallback,
    useEffect,
    useState,
    useRef,
} from 'react';
import classnames from 'classnames';

import { Decorators } from '../Select/components/Decorators/Decorators';
import { ErrorMessage } from '../../ErrorMessage';
import { Field } from '../Field/Field';
import { FormLabel } from '../FormLabel/FormLabel';
import { InputBorderContainer } from '../InputBorderContainer/InputBorderContainer';
import { AnimatedPlaceholderContainer } from '../AnimatedPlaceholderContainer/AnimatedPlaceholderContainer';
import { SIZES, DIRECTIONS } from '../constants';

import { type NamedSelectOption, type SelectOption } from '../Select/sharedTypes';
import { type CommonSingleSelectProps } from '../Select/BaseSelect';
import styles from './main.scss';

type NativeSelectProps = CommonSingleSelectProps;

export const NativeSelect: FunctionComponent<NativeSelectProps> = props => {
    const {
        autoFocus = false,
        clearable = true,
        errorMessageAlignment = DIRECTIONS.left,
        hasAnimatedPlaceholder = false,
        hasError = false,
        horizontalSpacing = SIZES.def,
        autoComplete,
        dataTn,
        disabled,
        errorMessage,
        isLoading,
        label,
        name,
        options,
        placeholder,
        size,
        onBlur,
        onChange,
        value = '',
        inputRefCallback,
    } = props;

    const [isFocused, setIsFocused] = useState(!!autoFocus);
    const [valueState, setValueState] = useState(value);

    const showError = hasError || !!errorMessage;
    const errorDataTnString = `${dataTn}-error`;

    const spacing = horizontalSpacing === SIZES.small ? SIZES.small : SIZES.medium;

    const selectElement = useRef<HTMLSelectElement | null>(null);

    const shouldShowAnimatedPlaceholder = useCallback(() => {
        return !!hasAnimatedPlaceholder && !!placeholder && !!valueState;
    }, [hasAnimatedPlaceholder, placeholder, valueState]);

    const getFakeNativePlaceholder = useCallback(
        (animatedPlaceholderRendered: boolean) => {
            if (!animatedPlaceholderRendered && !valueState) {
                return (
                    <option value="" disabled>
                        {placeholder}
                    </option>
                );
            }
            return null;
        },
        [valueState, placeholder]
    );

    const getEventOptionParam = (selectedValue: string): NamedSelectOption | null => {
        const selection = options.find((option: SelectOption) => option.value === selectedValue);
        const selectedLabel = selection && selection.label;

        if (selectedLabel && selectedValue) {
            return {
                label: selectedLabel,
                name,
                value: selectedValue,
            };
        }
        return null;
    };

    const handleBlur = (e: FocusEvent<HTMLSelectElement>): void => {
        setIsFocused(false);

        if (onBlur && e.target) {
            onBlur(getEventOptionParam(e.target.value));
        }
    };

    const handleChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        const newValue: string = e.target && e.target.value;

        setValueState(newValue);
        onChange(getEventOptionParam(newValue));
    };

    const handleClear = (): void => {
        setValueState('');
        onChange(null);
    };

    const handleFocus = (): void => {
        setIsFocused(true);
    };

    useEffect(() => {
        let nextStateValue = valueState;
        if (typeof value !== 'undefined') {
            nextStateValue = value;
        }
        setValueState(nextStateValue);

        const blurInput = (): void => {
            if (selectElement.current) {
                selectElement.current.blur();
            }
        };

        const focusInput = (): void => {
            if (selectElement.current) {
                selectElement.current.focus();
            }
        };

        if (isFocused) {
            focusInput();
        } else {
            blurInput();
        }
    }, [isFocused, selectElement, value, valueState]);

    return (
        <Field>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputBorderContainer
                disabled={disabled}
                hasError={showError}
                isFocused={isFocused}
                size={size}
            >
                <AnimatedPlaceholderContainer
                    marginLeft={spacing}
                    placeholder={placeholder}
                    showAnimatedPlaceholder={shouldShowAnimatedPlaceholder()}
                >
                    {({ placeholderDidRender }) => {
                        const fakeNativePlaceholder =
                            getFakeNativePlaceholder(placeholderDidRender);
                        const wrapperClasses = classnames(styles.selectWrapper, {
                            [styles.selectAnimatedPlaceholderPadding]: placeholderDidRender,
                        });

                        const selectClasses = classnames(styles.select, {
                            [styles.withFakeNativePlaceholder]: !!fakeNativePlaceholder,
                            [styles.twoDecoratorPadding]: isLoading || clearable,
                            [styles.threeDecoratorPadding]: isLoading && clearable,
                        });

                        return (
                            <div className={wrapperClasses}>
                                <select
                                    id={name}
                                    autoComplete={autoComplete}
                                    autoFocus={autoFocus}
                                    className={selectClasses}
                                    data-tn={dataTn}
                                    disabled={disabled}
                                    name={name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    ref={select => {
                                        selectElement.current = select;
                                        if (select && inputRefCallback) {
                                            inputRefCallback(select);
                                        }
                                    }}
                                    value={valueState}
                                >
                                    {fakeNativePlaceholder}
                                    {options.map((option: SelectOption, index: number) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        );
                    }}
                </AnimatedPlaceholderContainer>
                <Decorators
                    arrowDirection={DIRECTIONS.down}
                    arrowPaddingRight={spacing}
                    clearable={!!clearable}
                    dataTn={dataTn}
                    disabled={!!disabled}
                    isLoading={!!isLoading}
                    onClear={handleClear}
                    useNativeClickthrough
                />
            </InputBorderContainer>
            <ErrorMessage
                message={errorMessage}
                dataTn={errorDataTnString}
                alignRight={errorMessageAlignment === DIRECTIONS.right}
            />
        </Field>
    );
};
