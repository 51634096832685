import { type FunctionComponent } from 'react';
import * as React from 'react';

import { BaseSelect } from '../Common/Select/BaseSelect';
import { NoResultsOption } from '../Common/Select/components/NoResultsOption/NoResultsOption';

import { type CommonSingleSelectProps } from '../Common/Select/BaseSelect';

import styles from './main.scss';
import { NativeSelect } from '../Common/NativeSelect/NativeSelect';

type DefaultSingleSelectProps = CommonSingleSelectProps & {
    searchable?: false | null;
};

type SearchableSelectProps = CommonSingleSelectProps & {
    maskForPrivacy?: boolean;
    noResultsPrompt: React.ReactNode;
    searchable?: true;
};

export type SingleSelectProps = DefaultSingleSelectProps | SearchableSelectProps;

export const SingleSelect: FunctionComponent<SingleSelectProps> = props => {
    if (props.searchable && props.noResultsPrompt) {
        const { noResultsPrompt, ...commonProps } = props;
        return (
            <BaseSelect
                {...commonProps}
                customDropdownOption={({ filteredOptions, inputValue, isLoading }) => {
                    if (filteredOptions.length === 0 && inputValue && !isLoading) {
                        return <NoResultsOption>{noResultsPrompt}</NoResultsOption>;
                    } else {
                        return null;
                    }
                }}
            />
        );
    }

    const { searchable, ...nonSearchableProps } = props;
    return (
        <React.Fragment>
            <span className={styles.nonMobileWrapper}>
                <BaseSelect searchable={!!searchable} {...nonSearchableProps} />
            </span>
            <span className={styles.isMobileWrapper}>
                <NativeSelect {...nonSearchableProps} />
            </span>
        </React.Fragment>
    );
};
