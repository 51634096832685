import { type SyntheticEvent, type FC, type ReactNode } from 'react';
import classnames from 'classnames';
import Close from 'dibs-icons/exports/legacy/Close';
import { Spinner } from '../../../../Spinner';
import { RotatingArrow } from '../../../../RotatingArrow';
import { SIZES, type DIRECTIONS } from '../../../constants';

import styles from './Decorators.scss';

type DecoratorsProps = {
    arrowDirection: typeof DIRECTIONS.up | typeof DIRECTIONS.down;
    arrowPaddingRight: typeof SIZES.small | typeof SIZES.medium;
    children?: ReactNode;
    clearable: boolean;
    dataTn: string;
    disabled: boolean;
    isLoading: boolean;
    onClick?: (e: SyntheticEvent<HTMLDivElement>) => void;
    onMouseDown?: () => void;
    onClear: () => void;
    useNativeClickthrough?: boolean;
};

export const Decorators: FC<DecoratorsProps> = props => {
    const {
        arrowDirection,
        arrowPaddingRight,
        children,
        clearable,
        dataTn,
        disabled,
        onClick,
        onMouseDown,
        isLoading,
        onClear,
        useNativeClickthrough,
    } = props;
    const decoratorWrapperClassName = classnames(styles.decoratorWrapper, {
        [styles.nativeClickthrough]: useNativeClickthrough,
    });
    const spinnerClassName = classnames(styles.loadSpinner, {
        [styles.marginRight]: !clearable || !!children,
    });
    const childClassName = classnames({ [styles.marginRight]: !!children && !clearable });
    const clearableClassName = classnames(styles.clearable, {
        [styles.clearableEnabled]: !disabled,
    });

    return (
        // this disables needing a `role` and `tabIndex` property
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={decoratorWrapperClassName}
            onClick={onClick}
            onMouseDown={onMouseDown}
            onKeyDown={onClick}
        >
            {isLoading && (
                <div className={spinnerClassName} data-tn={`${dataTn}-loader`}>
                    <Spinner size={SIZES.tiny} />
                </div>
            )}
            {children && <div className={childClassName}>{children}</div>}
            {clearable && (
                <div
                    className={clearableClassName}
                    onClick={disabled ? undefined : onClear}
                    onKeyDown={disabled ? undefined : onClear}
                    data-tn={`${dataTn}-clear`}
                >
                    <Close />
                </div>
            )}
            <RotatingArrow direction={arrowDirection} paddingRight={arrowPaddingRight} />
        </div>
    );
};
