import { type FC, type ReactNode } from 'react';

import styles from './NoResultsOption.scss';

type NoResultsOptionProps = {
    children: ReactNode;
    dataTn?: string;
};

export const NoResultsOption: FC<NoResultsOptionProps> = ({ children, dataTn }) => {
    return (
        <div className={styles.noResults} data-tn={dataTn}>
            {children}
        </div>
    );
};
