import { type MouseEvent, type FC, type KeyboardEvent, type ReactNode } from 'react';
import classnames from 'classnames';
import styles from './main.scss';

type DropdownOptionProps = {
    children: ReactNode;
    dataTn?: string;
    id?: string;
    isHighlighted?: boolean;
    isSelected?: boolean;
    onClick?: (e: MouseEvent) => void;
    onKeyDown?: (e: KeyboardEvent) => void;
    onMouseDown?: () => void;
    onMouseMove?: () => void;
};

export const DropdownOption: FC<DropdownOptionProps> = ({
    children,
    dataTn,
    id,
    isHighlighted,
    isSelected,
    onClick,
    onKeyDown,
    onMouseDown,
    onMouseMove,
}) => {
    const className = classnames(styles.option, {
        [styles.optionSelected]: isSelected,
    });
    return (
        <>
            {/* a11y handled by downshift */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
                className={className}
                data-tn={dataTn}
                id={id}
                onClick={e => {
                    e.preventDefault();
                    if (onClick) {
                        onClick(e);
                    }
                }}
                onKeyDown={e => {
                    e.preventDefault();
                    if (onKeyDown) {
                        onKeyDown(e);
                    }
                }}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
            >
                <div
                    className={classnames(styles.optionBackdrop, {
                        [styles.optionHighlight]: isHighlighted,
                    })}
                />
                {children}
            </div>
        </>
    );
};
